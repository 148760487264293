import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import Top from '../components/blocks/top';
import Layout from "../components/layout"
import Seo from '../components/seo';

const BlogPostTemplate = ({ data: { previous, next, post, location } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout page={post} location={location}>
      <Seo
        seo={post.seo}
        title={post.title}
        featuredImage={post.featuredImage?.node?.localFile?.publicURL}
      />
      <Top item={{ text: '', small: true }} />

      <section className={`blogpost`} id="main">
        <article
          className="blog-post container-fluid"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="row">
            <div className="col-12 mb-5">
              <h1 itemProp="headline">{parse(post.title || '')}</h1>
            </div>
            <div className="col-md-7">
              {featuredImage?.fluid && (
                <Image
                  fluid={featuredImage.fluid}
                  alt={featuredImage.alt}
                  style={{ marginBottom: 50 }}
                  loading={`eager`}
                  fadeIn={false}
                />
              )}
              {!!post.content && (
                <article itemProp="articleBody">{parse(post.content || '')}</article>
              )}
            </div>
          </div>
        </article>

        <nav className="blog-post-nav d-none">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.uri} rel="prev">
                  ← {parse(previous.title || '')}
                </Link>
              )}
            </li>

            <li>
              {next && (
                <Link to={next.uri} rel="next">
                  {parse(next.title || '')} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </section>
    </Layout>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400, toFormat: JPG, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          id
          mediaDetails {
            width
            height
          }
          localFile {
            publicURL
          }
        }
        breadcrumbs {
          text
          url
        }
        schema {
          articleType
          pageType
          raw
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
